import React                   from 'react';
import PropTypes               from "prop-types";
import Flux                    from "../../../../../flux/Flux";
import {TileDisplayConfigType} from "../../../Grid2";

const PreviewPicture = props => {
	const item     = props.item;
	const tileSize = props.displayConfig.tileSize;

	const isWideImage = item.aspectRatio < props.containerAspectRatio;

	const imageSizes = Flux.Constants.Grid2.BREAKPOINTS_SMALL_TO_LARGE.filter(breakpoint => tileSize.hasValueForBreakpoint(breakpoint))
		.map(breakpoint => ({
			minViewportWidth: Flux.Constants.Grid2.BREAKPOINT_MIN_WIDTHS[breakpoint] || 0,
			imageBaseWidth:   tileSize.getValueAtBreakpoint(breakpoint) * 320,
		})).reverse();

	return (
		<picture>
			{imageSizes.map(({minViewportWidth, imageBaseWidth}, index) => {
				const srcUrls = [1, 2].map(size => ({
					size,
					url: getImageUrlForWidth(item.images, imageBaseWidth * size),
				})).filter(({url}) => !!url);

				const srcSet = srcUrls.map(({size, url}) => `${url} ${size}x`).join(', ');

				const source = (
					<source media={`(min-width: ${minViewportWidth}px)`}
					        srcSet={srcSet}
					        key={'source' + index}
					/>
				);

				const img = (
					<img className={'grid-2__tile__preview__picture' + (isWideImage ? ' -wide' : '') + (props.active ? ' -active' : '') + (props.transitioning ? ' -transition' : '')}
					     srcSet={srcSet}
					     src={srcUrls[0].url}
					     key={'img' + index}
					     loading={props.lazyImageLoading ? 'lazy' : 'eager'}
					/>
				);

				if (imageSizes.length === 1) {
					return [source, img];
				} else {
					if (minViewportWidth > 0) {
						return source;
					} else {
						return img;
					}
				}

			})}
		</picture>
	);
};

function getImageUrlForWidth(images, width) {
	for (const image of images) {
		if (image.size === `w${width}`) {
			return image.url;
		}
	}

	return null;
}

export const PreviewPictureItemType = PropTypes.shape({ // VXQL Photo type
	id:          PropTypes.number.isRequired, // Used in PreviewPictures
	aspectRatio: PropTypes.number.isRequired,
	images:      PropTypes.arrayOf(PropTypes.shape({ // VXQL PhotoImage type
		size: PropTypes.string.isRequired,
		url:  PropTypes.string.isRequired,
	})),
});

PreviewPicture.propTypes = {
	item:                 PreviewPictureItemType.isRequired,
	active:               PropTypes.bool,
	transitioning:        PropTypes.bool,
	containerAspectRatio: PropTypes.number,
	displayConfig:        TileDisplayConfigType.isRequired,
	lazyImageLoading:     PropTypes.bool,
};

PreviewPicture.defaultProps = {
	active:               true,
	transitioning:        false,
	containerAspectRatio: 9 / 16,
	lazyImageLoading:     false,
};

export default PreviewPicture;