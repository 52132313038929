import React     from 'react';
import PropTypes from "prop-types";

const FooterColumn = props => (
	<div className={'grid-2__tile__type-video__footer-column' + (props.grow ? ' -grow' : '') + (props.paddingLeft ? ' h-pl-5' : '') + (props.shrink ? ' -shrink' : '')}>
		{props.children}
	</div>
);

FooterColumn.propTypes = {
	grow:        PropTypes.bool,
	shrink:      PropTypes.bool,
	paddingLeft: PropTypes.bool,
	children:    PropTypes.node,
};

export default FooterColumn;