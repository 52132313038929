import React     from 'react';
import PropTypes from "prop-types";

const FooterRow = props => (
	<div className={'grid-2__tile__type-video__footer-row' + (props.grow ? ' -grow' : '') + (props.shrink ? ' -shrink' : '')}>
		{props.children}
	</div>
);

FooterRow.propTypes = {
	grow:     PropTypes.bool,
	shrink:   PropTypes.bool,
	children: PropTypes.node,
};

export default FooterRow;