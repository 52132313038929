import React from "react";
import PropTypes from "prop-types";

const PlayButton = (props) => (
	<i className={'grid-2__tile__preview__icon-play icon -icon-play-rounded-line' + (props.show ? ' -show' : '')}></i>
);

PlayButton.propTypes = {
	show: PropTypes.bool.isRequired,
};

export default PlayButton;