import React     from 'react';
import PropTypes from "prop-types";

class HoverContainer extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			hovering: false,
		};

		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
	}

	onMouseEnter() {
		this.setState({hovering: true});
	}

	onMouseLeave() {
		this.setState({hovering: false});
	}

	render() {
		const hovering = this.state.hovering;

		return (
			<div className={'grid-2__tile__hover-container'} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
				{React.Children.map(this.props.children, child => React.isValidElement(child) ? React.cloneElement(child, {hovering}) : child)}
			</div>
		);
	}
}

HoverContainer.propTypes = {
	children: PropTypes.node,
};

export default HoverContainer;