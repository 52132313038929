import React     from 'react';
import PropTypes from "prop-types";
import Flux      from "../../../../../flux/Flux";

const Styles    = Flux.Constants.Grid2.Footer.Label.Styles;
const FontScale = Flux.Constants.Grid2.Footer.Label.FontSizeScaling;

const STYLE_CLASSNAME_MAPPING = {
	[Styles.TITLE]:      '-title',
	[Styles.SUBTITLE]:   '-subtitle',
	[Styles.ACTOR_NAME]: '-actor-name',
};

const FONT_SCALE_CLASSNAME_MAPPING = {
	[FontScale.THREE_QUARTERS]: '-font-size-three-quarters',
	[FontScale.HALF]:           '-font-size-half',
};

const FooterLabel = props => (
	React.createElement(
		props.headlineType ? props.headlineType : 'div',
		{className: 'grid-2__tile__type-video__footer-label ' + getAdditionalClassNameByStyle(props.labelStyle) + ' ' + getAdditionalClassNameByFontScale(props.fontSizeScaling)},
		props.children || '\u200B',
	)
);

function getAdditionalClassNameByStyle(style) {
	return STYLE_CLASSNAME_MAPPING[style] || '';
}

function getAdditionalClassNameByFontScale(fontScale) {
	return FONT_SCALE_CLASSNAME_MAPPING[fontScale] || '';
}

FooterLabel.propTypes = {
	labelStyle:      PropTypes.oneOf(Object.values(Flux.Constants.Grid2.Footer.Label.Styles)),
	fontSizeScaling: PropTypes.oneOf(Object.values(Flux.Constants.Grid2.Footer.Label.FontSizeScaling)),
	children:        PropTypes.node,
	headlineType:    PropTypes.oneOf([
		Flux.Constants.Headlines.h1,
		Flux.Constants.Headlines.h2,
		Flux.Constants.Headlines.h3,
		Flux.Constants.Headlines.h4,
	]),
};

export default FooterLabel;
