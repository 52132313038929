import React                                    from 'react';
import PropTypes                                from "prop-types";
import PreviewPicture, {PreviewPictureItemType} from "./PreviewPicture";
import {TileDisplayConfigType}                  from "../../../Grid2";
import PlayButton                               from "./PlayButton";

const STATIC = 'STATIC';

class PreviewPictures extends React.PureComponent {
	static calculateDisplayedItems(items) {
		if (!Array.isArray(items)) {
			items = [];
		}

		// Remove consecutive pictures with the exact same urls (duplicates are still included if the images do not directly follow each other)
		let filteredItems = items.length > 1 ?
			items.filter((value, index, items) => index === 0 || !this.arePreviewPictureItemsEqual(items[index], items[index - 1])) :
			items;

		// Remove the last filtered item if it equals the first
		if (filteredItems.length > 1 && this.arePreviewPictureItemsEqual(filteredItems[filteredItems.length - 1], filteredItems[0])) {
			filteredItems = filteredItems.slice(0, -1);
		}

		return filteredItems;
	}

	static arePreviewPictureItemsEqual(item1, item2) {
		const imageUrls1 = item1.images.map(({url}) => url);
		const imageUrls2 = item2.images.map(({url}) => url);

		const sharedUrlsLength = imageUrls1.filter(value => imageUrls2.indexOf(value) !== -1).length;

		return sharedUrlsLength === imageUrls1.length && sharedUrlsLength === imageUrls2.length;
	}

	static getKeyForItem(item, index) {
		return `preview-picture-${index}-${item.id}`;
	}

	constructor(props) {
		super(props);

		this.state = {
			activeIndex:              STATIC,
			transitionIntervalHandle: 0,
			stopTransition:           false,
			transitionToStatic:       false,
		};

		this.displayedItemsCache = [];

		this.onTransition = this.onTransition.bind(this);
	}

	componentDidUpdate(prevProps) {
		const mouseEnter = !prevProps.hovering && this.props.hovering;
		const mouseLeave = prevProps.hovering && !this.props.hovering;

		if (mouseEnter || mouseLeave) {
			this.setState(state => {
				const nextState = {};

				// onMouseEnter
				if (mouseEnter && this.isHoverAvailable()) {
					if (state.transitionIntervalHandle) {
						nextState.stopTransition = false;
					} else {
						nextState.preload                  = true; // For preloading
						nextState.transitionIntervalHandle = setInterval(this.onTransition, 1200); // When changing the duration, change it in the CSS as well
					}
				}

				// onMouseLeave
				if (mouseLeave && state.transitionIntervalHandle) {
					nextState.stopTransition = true;
				}

				return nextState;
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.transitionIntervalHandle);
	}

	getNextImageIndex(state, props) {
		const displayedItems = this.getDisplayedItems(props);

		if (state.activeIndex === STATIC) {
			return displayedItems.length > 1 && PreviewPictures.arePreviewPictureItemsEqual(displayedItems[0], props.staticItem) ? 1 : 0;
		}

		if (state.transitionToStatic) {
			return STATIC;
		}

		return (state.activeIndex + 1) % displayedItems.length;
	}

	getDisplayedItems(props) { // Memoizes 5 values as it might be called with old or new props sometimes
		for (const entry of this.displayedItemsCache) {
			if (entry.items === props.items) {
				return entry.displayed;
			}
		}

		const newEntry = {
			items:     props.items,
			displayed: PreviewPictures.calculateDisplayedItems(props.items),
		};

		this.displayedItemsCache.unshift(newEntry);

		while (this.displayedItemsCache.length > 5) {
			this.displayedItemsCache.pop();
		}

		return newEntry.displayed;
	}

	onTransition() {
		this.setState((state, props) => {
			const nextState = {
				activeIndex: this.getNextImageIndex(state, props),
			};

			// When stopping transitions, we first transition back to the static preview and only afterwards stop the transition

			nextState.transitionToStatic = state.stopTransition && nextState.activeIndex !== STATIC;

			if (state.stopTransition && !nextState.transitionToStatic) {
				clearInterval(state.transitionIntervalHandle);
				nextState.stopTransition           = false;
				nextState.transitionIntervalHandle = 0;
			}

			return nextState;
		});
	}

	isHoverAvailable() {
		return this.getDisplayedItems(this.props).length > 1;
	}

	render() {
		const items         = this.getDisplayedItems(this.props);
		const transitioning = !!this.state.transitionIntervalHandle;
		const displayConfig = this.props.displayConfig;

		const renderedItems = [];

		renderedItems.push(this.state.activeIndex);

		if (this.state.preload) {
			renderedItems.push(this.getNextImageIndex(this.state, this.props));
		}

		return (
			<div className={'grid-2__tile__preview'}>
				{renderedItems.map((pictureIndex, index) => {
					const isStaticItem = pictureIndex === STATIC;
					const item         = isStaticItem ? this.props.staticItem : items[pictureIndex];

					return item ? (
						<PreviewPicture item={item}
						                key={PreviewPictures.getKeyForItem(item, isStaticItem ? 'static' : items.indexOf(item))}
						                active={index === 0}
						                transitioning={transitioning}
						                displayConfig={displayConfig}
						                lazyImageLoading={this.props.lazyImageLoading}
						/>
					) : null;
				})}
				{this.props.showPlayIconOnHover && <PlayButton show={this.props.hovering} />}
			</div>
		);
	}
}

PreviewPictures.propTypes = {
	staticItem:          PreviewPictureItemType.isRequired,
	items:               PropTypes.arrayOf(PreviewPictureItemType),
	showPlayIconOnHover: PropTypes.bool,
	hovering:            PropTypes.bool,
	displayConfig:       TileDisplayConfigType.isRequired,
	lazyImageLoading:    PropTypes.bool,
};

PreviewPictures.defaultProps = {
	items:               [],
	showPlayIconOnHover: true,
	hovering:            false,
	lazyImageLoading:    false,
};

export default PreviewPictures;