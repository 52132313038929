import React                   from 'react';
import PropTypes               from "prop-types";
import Grid2Helper             from "../../../../../utils/Grid2Helper";
import {TileDisplayConfigType} from "../../../Grid2";

const Footer = props => {
	const sizeClasses = Grid2Helper.getSizeClasses(props.displayConfig.tileSize);
	const additionalClasses = props.additionalClasses.join(' ');


	return (
		<div className={'grid-2__tile__type-video__footer ' + additionalClasses + ' ' + sizeClasses}>
			{props.children}
		</div>
	);
};

Footer.propTypes = {
	displayConfig:     TileDisplayConfigType.isRequired,
	children:          PropTypes.node,
	additionalClasses: PropTypes.array,
};

Footer.defaultProps = {
	additionalClasses: [],
};

export default Footer;